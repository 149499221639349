import gql from "graphql-tag";

export namespace ApplyRoomScene {

  export const mutation = gql`
    mutation($roomSceneId: String!) {
      applyRoomScene(roomSceneId: $roomSceneId)
    }
  `;

  export interface VariablesDefinition {
    roomSceneId: string;
  }

  export interface ResultDefinition {
    applyRoomScene: boolean;
  }

}
