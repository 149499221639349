










































import Vue from 'vue';
import first from 'lodash/first';

import MoreButton from '@/components/buttons/MoreButton.vue';
import SceneShortcut from '@/components/scenes/SceneShortcut.vue';
import OutlineRoundedButton from '@/components/buttons/OutlineRoundedButton.vue';

import { BuildingTopologyWithAreaName } from '@/graphqlRequests/queries/BuildingTopologyWithAreaName';
import { generateKey } from '@/helpers/StringGenerator';
import { SceneShortcut as SceneShortcutDefinition } from '@/interfaces/scenes/SceneShortcut';
import { ApplyRoomScene } from '@/graphqlRequests/mutations/ApplyRoomScene';
import { CustomDropdownAction } from '@/interfaces/dropdown/CustomDropdownAction';

export default Vue.extend({
  name: 'AreaControl',
  components: {
    MoreButton,
    SceneShortcut,
    OutlineRoundedButton,
  },
  data(): {
    editingMode: boolean;
    buildingTopology: BuildingTopologyWithAreaName.ContentDefinition | undefined;
  } {
    return {
      editingMode: false,
      buildingTopology: undefined,
    };
  },
  apollo: {
    buildingTopology: {
      skip(): boolean {
        return (
          this.$store.state.selectedBuildingId == undefined
          || this.$store.state.selectedAreaId == undefined
        );
      },
      query: BuildingTopologyWithAreaName.query,
      variables(): BuildingTopologyWithAreaName.VariablesDefinition {
        return {
          buildingId: this.$store.state.selectedBuildingId,
          areaIds: [this.$store.state.selectedAreaId],
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$store.commit('addLoadingProcess', this.buildingTopologyLoadingKey);
        } else {
          this.$store.commit('stopLoadingProcess', this.buildingTopologyLoadingKey);
        }
      },
    },
  },
  computed: {
    buildingTopologyLoadingKey(): string {
      return generateKey();
    },
    areaName(): string | undefined {
      return first(this.buildingTopology?.areas || [])?.name;
    },
    buildingName(): string | undefined {
      return this.buildingTopology?.name;
    },
    sceneShortcuts(): SceneShortcutDefinition[] {
      return this.$store.state.sceneShortcuts;
    },
    moreActions(): CustomDropdownAction[] {
      return [
        { key: 'edit', label: 'Edit', }
      ];
    },
  },
  methods: {
    customActionRequested(actionKey: string) {
      switch (actionKey) {
      case 'edit':
        this.switchEditing();
        return;
      default:
        return;
      }
    },
    switchEditing() {
      this.editingMode = !this.editingMode;
    },
    addSceneShortcut() {
      this.$router.push({
        name: 'ScenesSelector',
        params: {
          back: 'AreaControl',
        },
      });
    },
    removeSceneShortcut(sceneShortcut: SceneShortcutDefinition) {
      this.$store.commit('removeSceneShortcut', sceneShortcut);
    },
    async activateSceneShortcut(sceneShortcut: SceneShortcutDefinition) {
      const loadingKey = generateKey();
      try {
        this.$store.commit('addLoadingProcess', loadingKey);
        for (const roomSceneId of sceneShortcut.sceneIds) {
          await this.$apollo.mutate<ApplyRoomScene.ResultDefinition, ApplyRoomScene.VariablesDefinition>({
            mutation: ApplyRoomScene.mutation,
            variables: {
              roomSceneId,
            },
          });
        }
      } catch (e) {
        return;
      } finally {
        this.$store.commit('stopLoadingProcess', loadingKey);
      }
    },
  },
});
