

















import Vue from 'vue';

import { SceneShortcut } from '@/interfaces/scenes/SceneShortcut';

export default Vue.extend({
  name: 'SceneShortcut',
  props: {
    sceneShortcut: {
      type: Object as () => SceneShortcut,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
});
