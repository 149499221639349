




















import Vue from 'vue';

import { CustomDropdownAction } from '@/interfaces/dropdown/CustomDropdownAction';

export default Vue.extend({
  name: 'MoreButton',
  props: {
    customActions: {
      type: Array as () => CustomDropdownAction[],
      default: () => [],
    },
  },
  methods: {
    selectAnotherRoom() {
      this.$store.commit('resetToAreas');
      this.$router.push({ name: 'AreaSelector'});
    },
    reset() {
      this.$store.commit('dropState');
      this.$router.push({ name: 'Start'});
    },
  },
});
