import gql from "graphql-tag";

export namespace BuildingTopologyWithAreaName {

  export const query = gql`
    query buildingTopology($buildingId: String!, $areaIds: [String!]) {
      buildingTopology(buildingId: $buildingId) {
        id
        name
        areas: floors(ids: $areaIds) {
          id
          name
        }
      }
    }
  `;

  export interface VariablesDefinition {
    buildingId: string;
    areaIds: string[];
  }

  export interface ContentDefinition {
    id: string;
    name: string;
    areas: {
      id: string;
      name: string;
    }[];
  }

  export interface ResultDefinition {
    buildingTopology: ContentDefinition;
  }

}
